export default function PlusIcon(props){
  return(
    <>
      <svg width={props.width} className={props.className} height={props.height} viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.525879 46.7209H27.9259V74.1209H46.3259V46.7209H73.9259V28.3209H46.3259V0.920892H27.9259V28.3209H0.525879V46.7209Z" fill={props.color}/>
      </svg>
    </>
  )
}

