import React, { useEffect, useState } from "react";
import PlusIcon from "../components/CommonForBoth/PlusIcon";
import { Button } from "../components/core/button";
import HcLink from "../components/core/layout/link";
import HcText from "../components/core/layout/text";
import PricingOptions from "../components/pageElements/plans-pricing/pricing-options";
import dynamic from "next/dynamic";
import Head from "next/head";
import Image from "next/legacy/image";
import Link from "next/link";
import Router, { useRouter } from "next/router";

import folderIconIllustration from "/public/assets/images/folder-icon.svg";
import homepageSearchIllustration from "/public/assets/images/homepage-search.svg";
import plansCheckedIllustration from "/public/assets/icons/plans-checked.svg";
import searchIconIllustration from "/public/assets/images/search-icon.svg";
import settingsIconIllustration from "/public/assets/icons/settings-icon.svg";

import { useDispatch } from "react-redux";
import { Account } from "../store/account";
import { setSplashScreen } from "../store/common/commonSlice";
import { useAppSelector } from "../store/hook";

import api from "../api";

import { PackageModel, ProductModel, ProductPackageModel, UserModel } from "../gen/openapi";

const Footer = dynamic(
    () => import('../components/layout/homepage/footer').then((mod) => mod.Footer)
);

const Header = dynamic(
    () => import('../components/layout/homepage/header').then((mod) => mod.Header)
);

const Newsletter = dynamic(
    () => import('../components/layout/homepage/newsletter').then((mod) => mod.Newsletter)
);

const HomePage = () => {
  const router = useRouter();

  const dispatch = useDispatch();

  const [packageList, setPackageList] = useState<PackageModel[]>([]);
  const [packageWithPriceList, setPackageWithPriceList] = useState<PackageModel[]>([]);
  const [productPackageList, setProductPackageList] = useState<ProductPackageModel[]>([]);
  const [productPropertiesList, setProductPropertiesList] = useState<string[]>(
    []
  );
  const [x, setX] = useState<boolean>(false);

  const [toolsList, setToolsList] = useState<ProductModel[]>([]);

  const [userSubscription, setUserSubscription] = useState<any>(null);

  useEffect(() => {
    const checkUserSubscription = async () => {
      let _user: UserModel = JSON.parse(Account.User.get())

      if (_user) {
        // dispatch(setSplashScreen(true));

        const response = await api.request({
          api: 'AccountApi',
          method: 'apiAccountUsersCognitoUserIdUserGet',
          params: [
            Account.CognitoUserName.get()
          ],
          spinner: false
        });

        if (response && response.data && response.success) {
          const subscription = await api.request({
            api: 'SubscriptionApi',
            method: 'apiPaymentSubscriptionsSummaryUsersUserIdGet',
            params: [
              response.data.id
            ]
          });

          if (subscription.success) {
            setUserSubscription(subscription);
          } else {
            setUserSubscription([]);
          }
        } else
          setUserSubscription([]);

        // dispatch(setSplashScreen(false));
      } else
        setUserSubscription([]);
    }

    const getPackagesAndProducts = async () => {
      // dispatch(setSplashScreen(true));

      const args = await api.request({
        api: 'PackageApi',
        method: 'apiPaymentPackagesGet',
        anonymous: true
      });

      if (args.success && args.data) {
        args.data = args.data.filter(d => d.name.toLowerCase() !== 'free');

        const packageList = args.data.sort((a, b) => a.sortOrder - b.sortOrder);

        setPackageList(packageList);

        const response = await api.request({
          api: 'ProductPackageApi',
          method: 'apiPaymentProductPackagesGet',
          anonymous: true
        });

        if (response.success && response.data) {
          response.data = response.data.filter(
              (d) => d.product.name.toLowerCase() !== 'free'
          );

          for (let i = 0; i < args.data.length; i++) {
            args.data[i].price = response.data
                .filter((r) => r.packageId === args.data[i].id)
                .reduce(
                    (acc, obj) =>
                        acc + (!obj.product.addon ? obj.product.price : 0),
                    0
                );
            args.data[i].items = response.data
                .filter((r) => r.packageId === args.data[i].id)
                .sort((a, b) => a.product.sortOrder - b.product.sortOrder);
          }

          const packageList = args.data.sort(
              (a, b) => a.sortOrder - b.sortOrder
          );

          const productPropertiesList = [];
          for (let i = 0; i < packageList.length; i++) {
            const packageProductsWithoutAddOns = packageList[i].items.filter(
                (i) => i.product.addon == false
            );

            const packageProductProperties = [];
            for (let j = 0; j < packageProductsWithoutAddOns.length; j++) {
              const productProperties =
                  packageProductsWithoutAddOns[j].product.productProperties;

              for (let k = 0; k < productProperties.length; k++) {
                if (!packageProductProperties.includes(productProperties[k]))
                  packageProductProperties.push(productProperties[k]);

                if (!productPropertiesList.includes(productProperties[k]))
                  productPropertiesList.push(productProperties[k]);
              }
            }
            packageList[i].productProperties = packageProductProperties;
          }

          setProductPropertiesList(productPropertiesList);

          setPackageList(packageList);

          const productPackageList = response.data
              .filter((r) => r.packageId === args.data[0].id)
              .sort((a, b) => a.product.sortOrder - b.product.sortOrder);

          setProductPackageList(productPackageList);

        }
      }

      const productResponse = await api.request({
        api: 'ProductApi',
        method: 'apiPaymentProductsShowAllGet',
        params: [
          true
        ],
        anonymous: true
      });

      if (productResponse.success && productResponse.data) {
        let products = productResponse.data.filter(
            (a) =>
                a.billingPeriodType === 'Monthly' &&
                a.isActive === true &&
                a.name !== 'Free' && a.addon === true
        );
        setToolsList(products);
      }

      // dispatch(setSplashScreen(false));
    }

    dispatch(setSplashScreen(true));

    checkUserSubscription().then(() => getPackagesAndProducts()).then(() => dispatch(setSplashScreen(false)))
        .catch(async error => {
          console.log(error);

          const errorToast = await import('../toolkit/toaster').then(d => d.Errorful);

          errorToast({ message: error.message, exception: error });
        });
  }, []);

  useEffect(() => {
    if (packageWithPriceList.length > 0)
      setX(true)
  }, [packageWithPriceList]);

  useEffect(() => {
    if (packageList && productPackageList && !x) {
      let tempArray: Array<PackageModel> = [];

      packageList.forEach((element) => {
        let sum = 0;

        productPackageList
          .filter((x) => x.packageId === element.id)
          .forEach((element) => {
            sum += element.product.price;
          });

        element.price = sum;

        tempArray.push(element);
      });

      setPackageWithPriceList(tempArray);
    }
  }, [packageList, productPackageList]);

  const isSplashScreen: boolean = useAppSelector(
    (state) => state.commonSlice.isSplashScreen
  );

  if (isSplashScreen) {
    return (
      <div data-testid="loader" id={'globalLoader'}>
        <div className="loader"/>
      </div>
    );
  }

  const selectAndProceed = async (item) => {
    localStorage.setItem('lastSelectedPlan', item.name);

    await Router.push(
        {
          pathname: '/pricing',
          query: {
            lastSelectedPlan: item.name,
          },
        },
        '/pricing'
    );
  };

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="description" content="All Medical Codes" />
        <meta name="keywords" content="All Medical Codes, Homepage" />
        <meta name="author" content="GlobalHashTech" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <div className="homepage bg" style={{ zIndex: -99 }}>
        <div className='circle'/>
        {/*<img*/}
        {/*  src={`/assets/images/spiral-line.png`}*/}
        {/*  alt="spiral-line"*/}
        {/*  className="spiral-line"*/}
        {/*  style={{ top: '-10px', opacity: 0.5, zIndex: -2 }}*/}
        {/*/>*/}

        {/*<img*/}
        {/*  src={`/assets/images/spiral-line.png`}*/}
        {/*  alt="spiral-line"*/}
        {/*  className="spiral-line"*/}
        {/*  style={{ top: '47%', opacity: 0.5, zIndex: -2 }}*/}
        {/*/>*/}


        {/*<img*/}
        {/*  src={`/assets/images/spiral-line.png`}*/}
        {/*  alt="spiral-line"*/}
        {/*  className="spiral-line"*/}
        {/*  style={{ top: '68%', opacity: 0.5 }}*/}
        {/*/>*/}

        <PlusIcon width={45} height={45} color={'#0040D8'} className="plus-icon pos1" />
        <PlusIcon width={175} height={175} color={'#DEE8FF'} className="plus-icon pos2" />
        <PlusIcon width={86} height={86} color={'#6693FF'} className="plus-icon pos3" />

        <div className="next-image-homepage-search pos4">
          <Image
              src={homepageSearchIllustration}
              width={272}
              height={284}
              alt="homepage-search"
              priority={true}
          />
        </div>
        <PlusIcon width={140} height={140} color={'#B1CAFF'} className="plus-icon pos5" />
        <PlusIcon width={45} height={45} color={'#0040D8'} className="plus-icon pos6" />
        <PlusIcon width={175} height={175} color={'#DEE8FF'} className="plus-icon pos7" />
      </div>
      <div className="homepage">
        <Header />

        <main className="d-flex flex-column align-items-center">
          <div className="main-page-title">
            <span data-testid="hero" className="hc-c-blue-900">Hero Title</span>&nbsp;
            <span className="hc-c-blue-800">Comes Here</span>
          </div>
          <div className='main-page-subtitle'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Quam ultrices dui vitae maecenas adipiscing ultrices id sit enim.
          </div>

          <div className="main-page-subheader trial" id='features'>
            <span className='hc-c-blue-900'>Try Medical Codes</span>&nbsp;
            <span className='hc-c-blue-800'>7 days for free.</span>
          </div>

          <div className="advantages">
            <div className="advantage card">
              <div className="advantage-title">
              <div className="next-image-search-icon">
                <Image
                    src={searchIconIllustration}
                    width={16}
                    height={16}
                    alt="search-icon"
                />
              </div>
                Advantage 1
              </div>
              <div className="advantage-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Tempor facilisis non tellus sem nulla consequat maecenas.
                Amet mauris vulputate et sed accumsan.
                Aliquet nunc, viverra ullamcorper congue eros sed quis.
                Massa eu sagittis, semper vitae morbi
              </div>
            </div>

            <div className="advantage card">
              <div className="advantage-title">
                <div className="next-image-settings-icon">
                  <Image
                      src={settingsIconIllustration}
                      width={26}
                      height={27}
                      alt="settings-icon"
                      loading="lazy"
                  />
                </div>
                Advantage 2
              </div>
              <div className="advantage-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Tempor facilisis non tellus sem nulla consequat maecenas.
                Amet mauris vulputate et sed accumsan.
                Aliquet nunc, viverra ullamcorper congue eros sed quis.
                Massa eu sagittis, semper vitae morbi
              </div>
              <div className='advantage-footer'>*Available in Pro only</div>
            </div>

            <div className="advantage card">
              <div className="advantage-title">
                <div className="next-image-folder-icon">
                  <Image
                      src={folderIconIllustration}
                      width={26}
                      height={23}
                      alt="folder-icon"
                      loading="lazy"
                  />
                </div>
                Advantage 3
              </div>
              <div className="advantage-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Tempor facilisis non tellus sem nulla consequat maecenas.
                Amet mauris vulputate et sed accumsan.
                Aliquet nunc, viverra ullamcorper congue eros sed quis.
                Massa eu sagittis, semper vitae morbi
              </div>
              <div className='advantage-footer'>*Available in Pro only</div>
            </div>

          </div>

          <div className="main-page-subheader plans" id='plans'>
            <span className='hc-c-blue-900'>Choose the</span>&nbsp;
            <span className='hc-c-blue-800'>Best Plan For You.</span>
          </div>

          <div className='main-page-pricing'>
            <PricingOptions
              packageWithPriceList={packageWithPriceList}
              selectedPackage={null}
              userSubscription={userSubscription}
              productPropertiesList={productPropertiesList}
              setSelectedPackage={(_item) => selectAndProceed(_item)}
              selectText={Account.User.get() ? `Select` : `Start 7 day Trial`}
            />
          </div>

          <div className="main-page-subheader tools" id='tools'>
            <span className='hc-c-blue-900'>Select Your</span>&nbsp;
            <span className='hc-c-blue-800'>Tools</span>
          </div>

          <div className='main-page-tools'>
            {toolsList.map((item, index) => {
              return (
                <div className='tool card' key={index}>
                  <div className='tool-title'>{item.name}</div>
                  <hr />
                  <div className='tool-price'>${item.price.toFixed(2)}/Month</div>
                  <div className='tool-description'>
                    {item.productProperties && item.productProperties.length > 0 && item.productProperties.map((property, index) => {
                      return (
                        <div className='tool-property' key={index}>
                          <Image
                              src={plansCheckedIllustration}
                              width={20}
                              height={20}
                              alt="plans-checked"
                              loading="lazy"
                          />
                          <span>{property}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className='tool-buttons'>
                    <Button onClick={() => router.push('/pricing')} className={'get-started-btn'}>Buy Now</Button>
                    <HcLink size="medium" as={Link} href={`/sign-in`}>
                      <HcText
                        size="medium"
                        as={'u'}
                        style={{ fontWeight: '600', marginLeft: '46px' }}
                      >
                        Try Demo
                      </HcText>
                    </HcLink>


                  </div>
                </div>
              );
            })}

          </div>

          {/* DO NOT REMOVE THAT COMMENTED CODE BLOCK PLEASE */}
          {/*<div className="main-page-subheader testimonals" id='testimonals'>*/}
          {/*  <span className='hc-c-blue-900'>You Are in</span>&nbsp;*/}
          {/*  <span className='hc-c-blue-800'>Good Hands!</span>*/}
          {/*</div>*/}

          {/*<div className='main-page-testimonals'>*/}
          {/*  <div className='testimonals-slider'>*/}
          {/*    <div className='testimonals-slider-item card mt-5'>*/}
          {/*      <div className='image'><div className='testimonal-circle'/></div>*/}
          {/*      <div className='testimonal'>*/}
          {/*        <div className='quote'/>*/}
          {/*        <div className="testimonal-text">*/}
          {/*          Processing Prior Authorization used to take around eight hours.*/}
          {/*          Now, it’s about only 2 hours.*/}
          {/*        </div>*/}
          {/*        <div className='testimonal-author'>*/}
          {/*          -Ryan Baxter, CEO of Medico TPA*/}
          {/*        </div>*/}
          {/*        <div className='testimonal-link'>*/}
          {/*          <a href=''>Learn More About NCCI</a>*/}
          {/*        </div>*/}
          {/*      </div>*/}

          {/*    </div>*/}
          {/*    <div className='testimonals-slider-item card'>*/}
          {/*      <div className='image'><div className='testimonal-circle'/></div>*/}
          {/*      <div className='testimonal'>*/}
          {/*        <div className='quote'/>*/}
          {/*        <div className="testimonal-text">*/}
          {/*          Processing Prior Authorization used to take around eight hours.*/}
          {/*          Now, it’s about only 2 hours.*/}
          {/*        </div>*/}
          {/*        <div className='testimonal-author'>*/}
          {/*          -Ryan Baxter, CEO of Medico TPA*/}
          {/*        </div>*/}
          {/*        <div className='testimonal-link'>*/}
          {/*          <a href=''>Learn More About NCCI</a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='testimonals-slider-item card mt-5'>*/}
          {/*      <div className='image'><div className='testimonal-circle'/></div>*/}
          {/*      <div className='testimonal'>*/}
          {/*        <div className='quote'/>*/}
          {/*        <div className="testimonal-text">*/}
          {/*          Processing Prior Authorization used to take around eight hours.*/}
          {/*          Now, it’s about only 2 hours.*/}
          {/*        </div>*/}
          {/*        <div className='testimonal-author'>*/}
          {/*          -Ryan Baxter, CEO of Medico TPA*/}
          {/*        </div>*/}
          {/*        <div className='testimonal-link'>*/}
          {/*          <a href=''>Learn More About NCCI</a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="main-page-subheader support" id='support'>
            <span className='hc-c-blue-900'>We're Right Here</span>&nbsp;
            <span className='hc-c-blue-800'>When You Need Us</span>
          </div>

          {/* <div className='main-page-support'>

            <div className='support-item card'>
              <div className='support-item-header'>
                <div className='support-item-icon'>
                  <div className="next-image-search-icon">
                    <Image src={searchIconIllustration} width={16} height={16} alt="search-icon" />
                  </div>
                  Talk To Sales
                </div>
                <div className='support-item-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Tempor facilisis non tellus sem nulla consequat maecenas.
                  Amet mauris vulputate et sed accumsan.
                  Aliquet nunc, viverra ullamcorper congue eros sed quis. Massa eu sagittis,
                </div>
                <div className='support-item-link'>
                  <a href=''>Schedule a Meeting Now</a>
                </div>
              </div>
            </div>
            <div className='support-item card'>
              <div className='support-item-header'>
                <div className='support-item-icon'>
                  <div className="next-image-search-icon">
                    <Image src={searchIconIllustration} width={16} height={16} alt="search-icon" />
                  </div>
                  Visit Our FAQ Hub
                </div>
                <div className='support-item-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Tempor facilisis non tellus sem nulla consequat maecenas.
                  Amet mauris vulputate et sed accumsan.
                  Aliquet nunc, viverra ullamcorper congue eros sed quis. Massa eu sagittis,
                </div>
                <div className='support-item-link'>
                  <a href=''>Visit FAQ Hub Now</a>
                </div>
              </div>
            </div>

          </div> */}

          <Newsletter />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;

